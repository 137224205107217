<template>
    <div>
        <a-card class="assign_con">
            <a-tabs v-model:activeKey="activeKey">
                <a-tab-pane key="1" tab="客资分配设置">
                    <div class="assign_title">
                        <h3>客资自动分配规则</h3>
                        <div class="popover_con">
                            <a-popover placement="top">
                                <template #content>
                                    <p class="ls-content">客资分配即为客资设置跟进人</p>
                                </template>
                                <question-circle-outlined class="ls-icon" />
                            </a-popover>
                        </div>
                    </div>
                    <a-radio-group v-model:value="radio">
                        <div class="radio_con">
                            <a-radio :value="1">不启用自动分配</a-radio>
                            <div v-if="showT">
                                <p>客资不自动分配跟进人，跟进人完全以手动分配为准。</p>
                            </div>
                        </div>
                        <div class="radio_con">
                            <a-radio :disabled="disableRadio" :value="2">按照部门内员工顺序轮流分配</a-radio>
                            <div v-if="showTip">
                                <p>选择自动分配时，先选择想分配的部门，系统查询该部门内所有在线的员工，在部门内按顺序轮流设置员工为跟进人。</p>
                                <p class="assign_tip">当前参与分配的部门：
                                    <b style="color: red;font-weight: normal;" v-if="departmentIdNameList.length == 0">无</b>
                                    <b style="color: red;font-weight: normal;" v-for="(item, index) in departmentIdNameList"
                                        :key="index"> {{ item }} |</b>
                                    &nbsp;&nbsp;&nbsp;&nbsp;<span @click="getAssign('选择部门')">选择部门</span>
                                </p>
                            </div>
                        </div>
                        <div class="radio_con">
                            <a-radio :disabled="disableRadio" :value="3">按员工顺序轮流分配</a-radio>
                            <div v-if="showAssTip">
                                <p>当有新客资进来时，系统查看选择的在线员工然后按照顺序轮流将员工设置为跟进人。</p>
                                <p class="assign_tip">当前参与自动分配的员工：
                                    <b style="color: red;font-weight: normal;" v-if="employeeIdNameList.length == 0">无</b>
                                    <b style="color: red;font-weight: normal;" v-for="(item, index) in employeeIdNameList"
                                        :key="index">
                                        {{ item }} |</b>
                                    &nbsp;&nbsp;&nbsp;&nbsp;<span @click="getAssign('选择员工')">选择账号</span>
                                </p>
                            </div>
                        </div>
                    </a-radio-group>
                    <div class="btn_con">
                        <a-button type="primary" @click="saveAssignAll">保存修改</a-button>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="客资流失设置">
                    <div class="assign_title">
                        <h3>未跟进客资自动流入公海</h3>
                        <div class="popover_con">
                            <a-popover placement="top">
                                <template #content>
                                    <p class="ls-content">决定长时间未写跟进记录的客资是否自动流入公海，已成单的客户不受影响</p>
                                </template>
                                <question-circle-outlined class="ls-icon" />
                            </a-popover>
                        </div>
                    </div>
                    <a-radio-group v-model:value="automaticInflow">
                        <a-radio :value="false" style="margin-right: 40px;">否</a-radio>
                        <a-radio :value="true">是</a-radio>
                    </a-radio-group>
                    <div v-if="automaticInflow" style="color: #000000; margin-top: 40px;">
                        <span style="color: #000000;">超过</span>
                        <a-input v-model:value="days" placeholder="" style="width: 60px; margin: 0 10px;" />
                        <span style="color: #000000;">天未跟进，客资自动流入公海</span>
                    </div>
                    <div style="margin-top: 40px;">
                        <a-button type="primary" @click="save">保存</a-button>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </a-card>
        <a-modal :title="assignTitle" v-model:open="dialogAssignVisible"
            :wrapClassName="assignTitle == '选择员工' ? 'dialog_staffwidth' : 'dialog_width'">
            <div :class="assignTitle == '选择员工' ? 'staffCon' : ''">
                <div class="assignSelect_con">
                    <div class="assignSelect_con_r">
                        <p>部门</p>
                        <div class="checkBox_con">
                            <a-checkbox v-model:checked="checkAll" :indeterminate="isIndeterminate"
                                @change="handleCheckAllChange">
                                全选
                            </a-checkbox>
                        </div>
                        <a-divider />
                        <a-checkbox-group @change="changeCheck($event)" v-model:value="checkedeparts" :options="departs" />
                    </div>
                    <div class="assignSelect_con_r" v-if="assignTitle == '选择部门'">
                        <p>已选</p>
                        <div class="tag_con">
                            <a-tag v-for="(tag, index) in checkeDepartsName" :key="tag" closable
                                @close="deldeparts(tag, index)">
                                {{ tag }}
                            </a-tag>
                        </div>
                    </div>
                    <div class="assignSelect_con_r" v-else>
                        <p>员工</p>
                        <!-- <div class="checkBox_con">
                            <el-checkbox :indeterminate="isStaffIndeterminate" v-model="checkStaffAll"
                                @change="handleCheckStaffAllChange">全选</el-checkbox>不要
                            <a-checkbox-group v-model:value="checkedStaff" @change="handleCheckedStaffChange">
                                <a-checkbox v-for="staff in staffList" :label="staff.username" :key="staff.id">{{
                                    staff.username
                                }}</a-checkbox>
                            </a-checkbox-group>
                        </div> -->
                        <div class="checkBox_con">
                            <a-checkbox-group v-model:value="checkedStaff" :options="staffList"
                                @change="handleCheckedStaffChange" />
                        </div>

                    </div>
                </div>
                <div class="assignSelect_con staffSelect_con" v-if="assignTitle == '选择员工'">
                    <div class="assignSelect_con_r">
                        <p>已选</p>
                        <div class="tag_con">
                            <a-tag v-for="(item, index) in checkedStaffUsername" :key="item" closable
                                @close="delstaff(item, index)">
                                {{ item }}
                            </a-tag>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="stafftip" v-if="assignTitle == '选择员工'">鼠标拖拽可修改员工分配顺序</div> -->
            <template #footer class="dialog-footer">
                <a-button type="primary" @click="saveAssign">保存</a-button>
                <a-button @click="cancelAssign">取 消</a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
import { get, post } from "@/utils/http";
let departOptions = [];
let staffOptions = []
export default {
    name: 'CrmIndex',
    data() {
        return {
            activeKey: '1',
            radio: 1,
            disableRadio: false,
            showT: false,
            showTip: false,
            showAssTip: false,
            dialogAssignVisible: false,
            assignTitle: '',
            checkAll: false,
            checkedeparts: [],
            checkeDepartsName: [],
            departs: departOptions,
            isIndeterminate: true,
            checkStaffAll: false,
            checkedStaff: [],
            checkedStaffUsername: [],
            staffList: staffOptions,
            isStaffIndeterminate: true,
            dragIndex: '',
            enterIndex: '',
            timeout: null,
            statusAssign: '',
            departmentIdNameList: [],
            employeeIdNameList: [],
            allEmployeeList: [],

            automaticInflow: false,
            days: '',
        };
    },
    destroyed() {
        clearInterval(this.timeout)
        this.timeout = null
    },
    watch: {
        radio(newValue, oldValue) {
            newValue == 1 ? this.showT = true : this.showT = false
            newValue == 2 ? this.showTip = true : this.showTip = false
            newValue == 3 ? this.showAssTip = true : this.showAssTip = false
        },
    },
    async mounted() {
        this.getAllocation();
        this.getData();
        try {
            let result = await get("/system/automaticAllocation/list/get")
            departOptions = result.departments
            if (departOptions.length == 0) {
                this.disableRadio = true
                this.$message.error("请先新建部门以及对应部门下员工，再进行操作")
            }
        } catch (error) {
            this.$message.error("请求失败")
        }

    },

    methods: {
        async getAllocation() {
            try {
                let resultList = await get("/system/automaticAllocation/list")
                this.statusAssign = resultList
                let { status, departmentList, employeeList } = this.statusAssign

                if (status == '1') {
                    this.radio = 1
                    this.departmentIdNameList = []
                    this.employeeIdNameList = []
                    this.checkedStaffUsername = []
                    this.checkedStaff = []
                } else if (status == '2') {
                    this.radio = 2
                    const names = [];
                    for (let i = 0; i < departmentList.length; i++) {
                        names.push(departmentList[i].name);
                    }
                    this.departmentIdNameList = names
                    this.employeeIdNameList = []
                    this.checkedStaffUsername = []
                    this.checkedStaff = []
                } else {
                    this.radio = 3
                    const names = [];
                    for (let i = 0; i < employeeList.length; i++) {
                        names.push(employeeList[i].username);
                    }
                    this.employeeIdNameList = names
                    this.departmentIdNameList = []

                }
            } catch (error) {
                this.$message.error("请求失败")
            }
        },
        delstaff(val, index) {
            let array = this.checkedStaffUsername;
            let filteredArray = array.filter(item => item !== val);
            this.checkedStaffUsername = filteredArray
            this.checkedStaff.splice(index, 1);
        },
        deldeparts(val, index) {
            let array = this.checkeDepartsName;
            let filteredArray = array.filter(item => item !== val);
            this.checkeDepartsName = filteredArray
            this.checkedeparts.splice(index, 1);
        },
        cancelAssign() {
            this.dialogAssignVisible = false
            let { employeeList, departmentList } = this.statusAssign
            if (this.assignTitle == '选择员工') {
                if (this.employeeIdNameList.length == 0) {
                    this.checkedStaff = []
                    this.checkedStaffUsername = []
                    this.checkedeparts = []
                    this.checkAll = false
                } else {
                    const names = [];
                    for (let i = 0; i < employeeList.length; i++) {
                        names.push(employeeList[i].username);
                    }
                    this.employeeIdNameList = names
                }
            } else {
                if (this.departmentIdNameList.length == 0) {
                    this.checkedeparts = []
                    this.checkAll = false
                } else {
                    const names = [];
                    for (let i = 0; i < departmentList.length; i++) {
                        names.push(departmentList[i].name);
                    }
                    this.departmentIdNameList = names
                }

            }
        },
        async saveAssignAll() {
            let selectedEmployee, selectedDepartment, allocationType;
            if (this.radio == '3') {
                selectedEmployee = this.checkedStaff
                selectedDepartment = ""
                allocationType = "AUTOMATIC"
                if ((selectedEmployee.length == '0' && this.employeeIdNameList.length !== 0) || selectedEmployee.length == '0') {
                    this.$message.error("请重新选择当前参与自动分配的员工")
                    return false
                }
            }
            if (this.radio == '2') {
                selectedDepartment = this.checkedeparts
                selectedEmployee = ''
                allocationType = "AUTOMATIC"
                if ((selectedDepartment.length == '0' && this.departmentIdNameList.length !== 0) || selectedDepartment.length == '0') {
                    this.$message.error("请重新选择当前参与分配的部门")
                    return false
                }
            }
            if (this.radio == '1') {
                selectedDepartment = ''
                selectedEmployee = ''
                allocationType = "MANUAL"
            }
            try {
                let result = await post("/system/automaticAllocation/edit", { allocationType, selectedDepartment, selectedEmployee })
                if (result.code == '200') {
                    this.$message.success("客资分配规则修改成功")
                    this.getAllocation()
                    // this.$router.go(0)
                }
            } catch (error) {
                this.$message.error(error.message)
            }

        },
        async saveAssign() {
            this.dialogAssignVisible = false
            if (this.assignTitle == '选择员工') {
                this.employeeIdNameList = this.checkedStaffUsername
            } else {
                this.departmentIdNameList = this.checkeDepartsName

            }
        },
        dragstart(index) {
            //console.log('start index ===>>> ', index)
            this.dragIndex = index
        },
        dragenter(e, index) {
            //console.log('e, index ===>>> ', e, index)
            e.preventDefault();
            this.enterIndex = index
            if (this.timeout !== null) {
                clearTimeout(this.timeout)
            }
            this.timeout = setTimeout(() => {
                if (this.dragIndex !== index) {
                    const source = this.checkedStaff[this.dragIndex]
                    this.checkedStaff.splice(this.dragIndex, 1)
                    this.checkedStaff.splice(index, 0, source)
                    this.dragIndex = index;
                }
            }, 100);
        },
        dragover(e, index) {
            //console.log('e, index ===>>> ', e, index)
            e.preventDefault();
        },
        handleClick(e) {
            // console.log(e);
        },
        async getAssign(assignTitle) {
            this.dialogAssignVisible = true
            this.assignTitle = assignTitle
            //得到所有的部门
            const transformedOptions = departOptions.map(option => ({
                label: option.name,
                value: option.id
            }));
            this.departs = transformedOptions
            if (this.assignTitle == '选择部门') {
                this.checkeDepartsName = this.departmentIdNameList
                const ids = [];
                this.checkeDepartsName.forEach(name => {
                    const item = this.departs.find(obj => obj.label === name);
                    if (item) {
                        ids.push(item.value);
                    }
                });
                this.checkedeparts = ids
                ids.length == this.departs.length ? this.checkAll = true : this.checkAll = false
            } else {
                //选择员工 。
                if (this.checkedStaff.length == 0) {
                    this.staffList = []
                    this.checkedeparts = []
                    this.isIndeterminate = false
                    this.checkAll = false
                }
                if (this.statusAssign && this.statusAssign.departments) {
                    this.checkedeparts = []
                    this.isIndeterminate = false
                    this.checkAll = false
                }
                if (this.statusAssign.employeeList) {
                    //获取已选分配员工的部门id 
                    let idArray = this.statusAssign.employeeList.map(item => item.departmentId);
                    //得到对应部门id下的员工
                    let promises = idArray.map(async (item) => {
                        let result = await get("/system/automaticAllocation/employeeList", { departmentId: item })
                        return result.employees;
                    });
                    Promise.all(promises)
                        .then((staffArray) => {
                            let combinedArray = staffArray.flat();
                            let uniqueArray = Array.from(new Set(combinedArray.map(JSON.stringify)), JSON.parse);
                            //得到对应部门下所有员工列表 
                            this.staffList = uniqueArray
                            this.allEmployeeList = uniqueArray
                            this.staffList = uniqueArray.map(option => ({
                                label: option.username,
                                value: option.id
                            }));
                        })
                        .catch((error) => {
                            console.error(error);
                        });


                    let targetData = departOptions.filter(item => idArray.includes(item.id));
                    let checkedArray = targetData.map(item => item.id);
                    this.checkedeparts = checkedArray
                }
                //选中的员工[1,2,3]
                if (this.statusAssign.employeeList) {
                    this.checkedStaff = this.statusAssign.employeeList.map(item => item.id)
                    this.checkedStaffUsername = this.statusAssign.employeeList.map(item => item.username)
                }

            }
        },
        handleCheckAllChange(val) {
            let checked = val.target.checked
            if (checked) {
                if (this.assignTitle == '选择部门') {
                    const names = [];
                    const ids = [];
                    for (let i = 0; i < this.departs.length; i++) {
                        names.push(this.departs[i].label);
                        ids.push(this.departs[i].value);
                    }
                    this.checkedeparts = ids
                    this.checkeDepartsName = names
                } else {
                    let idArray = this.departs.map(item => item.value);
                    let promises = idArray.map(async (item) => {
                        let result = await get("/system/automaticAllocation/employeeList", { departmentId: item })
                        return result.employees;
                    });
                    Promise.all(promises)
                        .then((staffArray) => {
                            let combinedArray = staffArray.flat();
                            let uniqueArray = Array.from(new Set(combinedArray.map(JSON.stringify)), JSON.parse);
                            this.staffList = uniqueArray
                            this.allEmployeeList = uniqueArray
                            this.staffList = uniqueArray.map(option => ({
                                label: option.username,
                                value: option.id
                            }));
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                    let targetData = departOptions.filter(item => idArray.includes(item.id));
                    let checkedArray = targetData.map(item => item.id);
                    this.checkedeparts = checkedArray
                }
            } else {
                if (this.assignTitle == '选择部门') {
                    this.checkedeparts = []
                    this.checkeDepartsName = []
                } else {
                    this.staffList = []
                    this.checkedeparts = []
                }

            }
            this.isIndeterminate = false;
        },
        async changeCheck(val) {
            if (this.assignTitle == '选择员工') {
                if (val) {
                    let idArray = val;
                    idArray.length == this.departs.length ? this.checkAll = true : this.checkAll = false
                    let promises = idArray.map(async (item) => {
                        let result = await get("/system/automaticAllocation/employeeList", { departmentId: item })
                        return result.employees;
                    });
                    Promise.all(promises)
                        .then((staffArray) => {
                            let combinedArray = staffArray.flat();
                            let uniqueArray = Array.from(new Set(combinedArray.map(JSON.stringify)), JSON.parse);
                            this.staffList = uniqueArray
                            this.allEmployeeList = uniqueArray
                            this.staffList = uniqueArray.map(option => ({
                                label: option.username,
                                value: option.id
                            }));
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            } else {
                if (val) {
                    let idArray = val;
                    idArray.length == this.departs.length ? this.checkAll = true : this.checkAll = false
                    const names = [];
                    idArray.forEach(id => {
                        const item = this.departs.find(obj => obj.value === id);
                        if (item) {
                            names.push(item.label);
                        }
                    });
                    this.checkeDepartsName = names
                }

            }
        },
        async handlecheckedepartsChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.departs.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.departs.length;
        },
        handleCheckStaffAllChange(val) {
            if (val) {
                const names = [];
                for (let i = 0; i < this.staffList.length; i++) {
                    names.push(this.staffList[i].name);
                }
                this.checkedStaff = names
            } else {
                this.checkedStaff = []
            }
            this.isStaffIndeterminate = false;
        },
        handleCheckedStaffChange(value) {
            let checkedCount = value.length;
            this.checkStaffAll = checkedCount === this.staffList.length;
            this.isStaffIndeterminate = checkedCount > 0 && checkedCount < this.staffList.length;
            //找到对应的 username
            let targetData = this.allEmployeeList.filter(item => this.checkedStaff.includes(item.id));
            this.checkedStaffUsername = targetData.map(item => item.username)
        },

        async getData() {
            try {
                let res = await get("/system/customerCapitalSetting/list");
                if (res.code === '200') {
                    this.automaticInflow = res.automaticInflow;
                    this.days = res.days
                }
            } catch (error) {
                this.$message.error("请求失败")
            }
        },

        async save() {
            let reg = new RegExp(/(^[1-9]\d{0,2}$)/);
            if (this.automaticInflow) {
                if (reg.test(this.days) == false) {
                    this.$message.error("请输入999以内的正整数");
                    return
                };
            }
            try {
                let res = await post("/system/customerCapitalSetting/edit", {
                    automaticInflow: this.automaticInflow,
                    days: this.days
                });
                if (res.code === '200') {
                    this.$message.success("保存成功");
                }
            } catch (error) {
                this.$message.error(error.message)
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.assign_title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 30px;

    .popover_con {
        padding-left: 5px;
    }
}

.dialog_width {
    width: 35%;
    min-width: 550px;
}

.dialog_staffwidth {
    width: 40%;
    min-width: 550px;
}

.assign_con :deep(.ant-card-body) {
    padding: 40px 20px 20px 50px;
}

.assign_con h3 {
    font-weight: 500;
    margin-bottom: 50px;
}

.assign_con .radio_con:nth-child(1) {
    margin-top: 0;
}

.assign_con .radio_con {
    margin-top: 50px;
}



.assign_con :deep(.ant-radio-wrapper) span {
    font-size: 16px;
}

:deep(.ant-divider-horizontal) {
    margin: 0;
}

:deep(.ant-checkbox-group) {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 360px;
    overflow-y: scroll;
}

:deep(.ant-checkbox-group::-webkit-scrollbar) {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
}

:deep(.ant-checkbox-group::-webkit-scrollbar-thumb) {
    background-color: #ddd;
    border-radius: 3px;
}

.checkBox_con>:deep(.ant-checkbox-wrapper) {
    padding: 10px;
}

.assign_con p {
    color: #666;
    padding-left: 25px;
    font-size: 14px;
}

.assign_con .btn_con {
    margin-top: 50px;
}

.assign_con span {
    color: #169BD5;
    cursor: pointer;
}

.assign_con .assign_tip {
    color: #0f0f0f;
    margin-top: 20px;
}

.assignSelect_con {
    width: 100%;
    height: 450px;
    border: 1px #eee solid;
    border-radius: 10px;
    display: flex;
    flex-direction: row;

    .assignSelect_con_r:nth-child(1) {
        border-right: 1px #eee solid;
    }

    .assignSelect_con_r {
        width: 50%;
        height: 100%;

        p {
            margin: 0;
            padding-left: 10px;
            color: #0f0f0f;
            font-size: 15px;
            line-height: 45px;
            height: 45px;
            border-bottom: 1px #eee solid;
        }
    }
}





.checkBox_con :deep(.a-checkbox-group) .a-checkbox.is-checked {
    background: #e0f6ff;
}

.checkBox_con :deep(.a-checkbox) {
    padding: 8px 0;
    margin-right: 0;
    padding-left: 10px;
}

.checkBox_con :deep(.a-checkbox__label) {
    font-size: 15px;
}

.a-checkbox-group {
    display: flex;
    flex-direction: column;
}

.tag_con {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    overflow-y: auto;
    height: calc(100% - 45px);

    div {
        background-color: #f4f4f5;
        border-color: #e9e9eb;
        color: #909399;
        margin-bottom: 5px;
        font-size: 14px;
        color: #0f0f0f;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        i {
            color: #909399;
        }

        i:hover {
            color: #FFF;
            background-color: #909399;
        }
    }

    span {
        background-color: #f4f4f5;
        border-color: #e9e9eb;
        color: #909399;
        margin-bottom: 5px;
        font-size: 14px;
        color: #0f0f0f;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        i {
            color: #909399;
        }

        i:hover {
            color: #FFF;
            background-color: #909399;
        }
    }
}

// 滚动条的宽度
.checkBox_con::-webkit-scrollbar,
.tag_con::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
}

// 滚动条的滑块
.checkBox_con::-webkit-scrollbar-thumb,
.tag_con::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
}

.checkBox_con::-webkit-scrollbar,
.tag_con::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
}

.staffCon {
    display: flex;
    flex-direction: row;
}

.staffSelect_con .assignSelect_con_r {
    width: 100% !important;
}

.staffSelect_con .assignSelect_con_r:nth-child(1) {
    border-right: none;
}

.staffSelect_con {
    margin-left: 20px;
    width: 350px;
}

.stafftip {
    margin-top: 15px;
    text-align: right;
}

.list-item {
    transition: transform .3s;
    cursor: move;
}
</style>